import { gsap, Power1 } from 'gsap';

const titleAnimations = (ref) => {
  const words = ref.current.querySelectorAll('#word');
  const tl = gsap
    .timeline()
    .fromTo(ref.current, { opacity: 0 }, { opacity: 1, duration: 0 })
    .fromTo(
      words,
      { y: '100%' },
      {
        y: 0,
        stagger: 0.06,
        duration: 0.5,
        ease: Power1.easeOut,
      },
      -0.5
    );

  return tl;
};

export default titleAnimations;
