import React from 'react';

import { WordWrapper, Word } from './styles';

const SplitText = ({ children, wordMargin }) => {
  return (
    <>
      {children.split(' ').map((char, index) => (
        <WordWrapper
          key={char + index}
          aria-hidden="true"
          wordMargin={wordMargin}
        >
          <Word id="word">{char + ' '}</Word>
        </WordWrapper>
      ))}
    </>
  );
};

export default SplitText;
