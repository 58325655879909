import { gsap, Power1 } from 'gsap';

const slideUpAnimations = (ref) => {
  const tl = gsap
    .timeline()
    .fromTo(
      ref.current,
      { autoAlpha: 0, y: '40px' },
      { autoAlpha: 1, y: 0, duration: 0.5, ease: Power1.easeOut},
      0
    );

  return tl;
};

export default slideUpAnimations;
