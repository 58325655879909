import { gsap, Power1 } from 'gsap';

const breadcrumbsAnimation = (ref) => {
  const button = ref.current.querySelector('button');
  const text = ref.current.querySelector('#breadcrumbs-text');

  const tl = gsap
    .timeline()
    .fromTo(ref.current, { opacity: 0 }, { opacity: 1, duration: 0 })
    .fromTo(
      button,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.8, ease: Power1.easeOut },
      0.3
    )
    .fromTo(
      text,
      { autoAlpha: 0, y: '-10px' },
      { autoAlpha: 1, y: 0, duration: 0.8, ease: Power1.easeOut },
      '+=0'
    );

  return tl;
};

export default breadcrumbsAnimation;
