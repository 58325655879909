import styled, { css } from 'styled-components';
import Link from 'gatsby-plugin-transition-link';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { h2 } from 'components/UI/Typography/styles';

export const ResourceWrapper = styled.div`
  position: relative;
  padding-bottom: 30px;
  width: 100%;
  height: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 146px 0 30px 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 214px 0 30px 0;
  }
`;

export const StandartResourceContent = styled.div`
  overflow-y: hidden;
  & .excerpt {
    margin-bottom: 60px;
  }
`;

export const BlocksContainer = styled.div`
  width: 100%;
  background-color: transparent;
  border-radius: 5px;
  box-shadow: 0px 8px 24px rgba(30, 56, 74, 0.15);

  padding: 0 20px 30px 20px;

  opacity: ${(props) => (props.animated ? 0 : 1)};

  &.SpecificResource {
    padding-top: 30px;
  }

  &.white {
    background-color: ${(props) => props.theme.colors.white};
  }

  @media (min-width: 500px) {
    padding: 30px;
    padding-top: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 60px;
    padding-top: 30px;

    &.SpecificResource {
      padding-top: 60px;
    }
  }
`;

export const BreadcrumbsContainer = styled.div`
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-top: 30px;
  }
`;

export const Title = styled.h1`
  max-width: 745px;
  padding: 15px 0 30px 0;
  color: ${(props) => props.theme.colors.white};
  font-size: 17px;

  &.secondary {
    padding: 37px 0 0 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fonts.h1_tablet};
    &.secondary {
      padding: 18px 0 0 0;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.h1_desktop};
    &.secondary {
      padding: 30px 0 0 0;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.theme.fonts.h1_large_desktop};
  }
`;

export const AdditionalPadding = styled.div`
  width: 100%;
  height: 98px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    height: 104;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    height: 90px;
  }
`;

export const Excerpt = styled.p`
  max-width: 502px;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fonts.body_mobile};
  line-height: ${(props) => props.theme.lineHeight.body_mobile};
  font-weight: ${(props) => props.theme.fontWeight.body_mobile};
  opacity: ${(props) => (props.animated ? 0 : 1)};

  &.blue {
    padding-bottom: 30px;
    color: ${(props) => props.theme.colors.blue};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      padding-bottom: 62px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      font-size: ${(props) => props.theme.fonts.body_desktop};
      line-height: ${(props) => props.theme.lineHeight.body_desktop};
      font-weight: ${(props) => props.theme.fontWeight.body_desktop};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
      font-size: ${(props) => props.theme.fonts.body_large_desktop};
      line-height: ${(props) => props.theme.lineHeight.body_large_desktop};
      font-weight: ${(props) => props.theme.fontWeight.body_large_desktop};
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: ${(props) => props.theme.fonts.body_tablet};
    line-height: ${(props) => props.theme.lineHeight.body_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.body_xl_desktop};
    line-height: ${(props) => props.theme.lineHeight.body_xl_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_xl_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    font-size: ${(props) => props.theme.fonts.body_xl_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.body_xl_large_desktop};
    font-weight: ${(props) => props.theme.fontWeight.body_xl_large_desktop};
  }
`;

export const StyledSubtitle = css`
  ${h2};
  color: ${(props) => props.theme.colors.blue};
  max-width: 496px;
  padding-bottom: 16px;
`;

export const Subtitle = styled.h2`
  ${StyledSubtitle};
`;

export const ResourceBlockContainer = styled.div``;

export const SpecificResourceContent = styled.div`
  overflow-y: hidden;
`;

export const SpecificResourceContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    background-image: url('/images/menu-background.svg');
    background-repeat: no-repeat;
    background-size: auto 60%;
    background-position: bottom right -350px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    background-position: bottom right -250px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    background-position: bottom right;
  }
`;

export const NavList = styled.ul`
  max-width: 230px;
  padding: 0;
  border-right: 1px solid ${(props) => props.theme.colors.grey};
  list-style-type: none;
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }

  & li:first-of-type {
    margin-top: 0;
  }
`;

export const NavItem = styled.li`
  list-style-type: none;
  max-width: 213px;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ThemeLinkStyle = css`
  display: inline-block;
  padding: 7px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.blue};
  font-size: ${(props) => props.theme.fonts.body_mobile};
  line-height: ${(props) => props.theme.lineHeight.body_mobile};
  font-weight: 500;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: ${(props) => props.theme.fonts.body_tablet};
    line-height: ${(props) => props.theme.lineHeight.body_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.body_desktop};
    line-height: ${(props) => props.theme.lineHeight.body_desktop};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.theme.fonts.body_large_desktop};
    line-height: ${(props) => props.theme.lineHeight.body_large_desktop};
  }
`;

export const StyledLink = styled(AnchorLink)`
  ${ThemeLinkStyle};
  &.active {
    font-weight: 700;
    border-left: 4px solid ${(props) => props.theme.colors.lime};
    height: 100%;
  }
`;

export const BlocksContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 53px;
  }
`;

export const ItemsContainer = styled.div`
  width: 100%;

  > div:last-child {
    padding-top: 30px;
  }
`;

export const Select = styled.select`
  appearance: none;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-radius: 5px;
  margin: 0;
  width: 100%;
  outline: none;
  padding: 10px;
  padding-right: 25px;
  color: ${(props) => props.theme.colors.blue};

  &::-ms-expand {
    display: none;
  }
`;

export const SelectLabel = styled.label`
  font-weight: 700;
  color: ${(props) => props.theme.colors.blue};
  display: block;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`;

export const SelectContainer = styled.div`
  position: relative;
  margin-top: 9px;
  margin-bottom: 30px;
  max-width: 384px;
  cursor: pointer;
`;

export const Arrow = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const TitleWrapper = styled.div`
  overflow-y: hidden;
  opacity: 0;
`;
