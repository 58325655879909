import React, { useEffect, useRef, useContext } from 'react';
import { navigate } from 'gatsby';
import { gsap } from 'gsap';
import Container from 'components/Container';
import Breadcrumbs from 'components/UI/Breadcrumbs';
import ResourceBlock from 'components/UI/ResourceBlock';
import Icon from 'components/Icon/';
import CustomSplitText from 'components/CustomSplitText';
import {
  ResourceWrapper,
  Title,
  Excerpt,
  StandartResourceContent,
  BlocksContainer,
  BreadcrumbsContainer,
  ResourceBlockContainer,
  SpecificResourceContent,
  StyledLink,
  NavItem,
  NavList,
  Subtitle,
  BlocksContentContainer,
  ItemsContainer,
  SpecificResourceContainer,
  Select,
  SelectLabel,
  SelectContainer,
  Arrow,
  AdditionalPadding,
  TitleWrapper,
} from './styles';

import breadcrumbsAnimation from 'utils/animations/breadcrumbsAnimation';
import slideUpAnimations from 'utils/animations/slideUpAnimations';
import titleAnimations from 'utils/animations/titleAnimations';

import AnimationContext from 'context/AnimationProvider';

const Resource = (props) => {
  const { location, path } = props;
  const data = props?.data?.contentfulResourcePage;

  let activePath = '';
  const array = location.pathname.split('/');
  activePath = array[array.length - 1];

  const { animationExit } = useContext(AnimationContext);

  const breadcrumbsRef = useRef();
  const titleRef = useRef();
  const subtitleRef = useRef();
  const resourceBlockContainerRef = useRef();
  const tl = useRef(null);

  useEffect(() => {
    if (data?.relatedResources) {
      const handle = data?.parentResource?.handle;
      navigate(`/${handle}/#dropdown`);
    }
    const breadcrumbsTl = breadcrumbsAnimation(breadcrumbsRef);
    const titleTl = titleAnimations(titleRef);
    const subtitleTl = slideUpAnimations(subtitleRef);
    const resourceBlockContainerTl = slideUpAnimations(
      resourceBlockContainerRef
    );

    tl.current = gsap
      .timeline()
      .add(breadcrumbsTl)
      .add(titleTl, '-=.5')
      .add(subtitleTl, '-=.4')
      .add(resourceBlockContainerTl, '-=.3')
    return () => {
      tl.current.kill();
    };
  }, []);

  useEffect(() => {
    if (animationExit) {
      tl.current.timeScale(2.5).reverse(0);
    }
  }, [animationExit]);

  const refSpecificResource = useRef(null);

  useEffect(() => {
    if (!refSpecificResource.current) return;

    if (location.hash === '#scroll') {
      refSpecificResource.current.scrollIntoView();
    }
  }, [refSpecificResource.current]);

  const exitPage = () => {
    tl.current.timeScale(2).reverse(0);
  };

  return (
    <ResourceWrapper>
      <Container>
        {data?.parentResource?.relatedResources ? (
          <SpecificResourceContent>
            <Breadcrumbs
              breadcrumbsTextTablet="Retour"
              props={props}
              setRef={breadcrumbsRef}
              exitDelay={2}
              onClick={exitPage}
              animated
            />

            {data?.parentResource?.title && (
              <TitleWrapper ref={titleRef}>
                <Title
                  className="secondary"
                  aria-label={data.parentResource.title}
                >
                  <CustomSplitText>{data.parentResource.title}</CustomSplitText>
                </Title>
              </TitleWrapper>
            )}
            <AdditionalPadding ref={refSpecificResource}></AdditionalPadding>
            <SpecificResourceContainer>
              <BlocksContainer className="SpecificResource">
                <SelectLabel for="select">
                  Choisir
                  <SelectContainer>
                    <Select
                      id="select"
                      onChange={(e) => navigate(event.target.value)}
                    >
                      {data?.parentResource?.relatedResources?.map((item) => {
                        return (
                          <option selected={activePath === item.handle ? true : false} value={`../${item.handle}`}>
                            {item.title}
                          </option>
                        );
                      })}
                    </Select>

                    <Arrow>
                      <Icon type="arrow-select" />
                    </Arrow>
                  </SelectContainer>
                </SelectLabel>
                {data?.title && (
                  <Subtitle className="blue">{data?.title}</Subtitle>
                )}
                {data?.excerpt && (
                  <Excerpt className="blue">{data?.excerpt}</Excerpt>
                )}
                <BlocksContentContainer>
                  <nav>
                    <NavList>
                      {data?.parentResource?.relatedResources?.map((item) => {
                        return (
                          <NavItem>
                            <StyledLink
                              className={
                                activePath === item.handle ? 'active' : ''
                              }
                              to={`../${item.handle}#scroll`}
                              key={item.id}
                            >
                              {item.title}
                            </StyledLink>
                          </NavItem>
                        );
                      })}
                    </NavList>
                  </nav>

                  <ItemsContainer>
                    {data?.resourceBlocks?.map((block) => {
                      return (
                        <ResourceBlockContainer className="grey" key={block.id}>
                          <ResourceBlock
                            title={block.title}
                            body={block.body}
                            ctas={block.ctAs}
                            className="grey small"
                            tags={block.tags}
                          />
                        </ResourceBlockContainer>
                      );
                    })}

                    <Breadcrumbs
                      breadcrumbsText="Retour"
                      theme="secondary"
                      props={props}
                      exitDelay={2}
                      onClick={exitPage}
                    />
                  </ItemsContainer>
                </BlocksContentContainer>
              </BlocksContainer>
            </SpecificResourceContainer>
          </SpecificResourceContent>
        ) : (
          <StandartResourceContent>
            <Breadcrumbs
              breadcrumbsTextTablet="Retour"
              props={props}
              setRef={breadcrumbsRef}
              exitDelay={2}
              onClick={exitPage}
              animated
            />
            {data?.title && (
              <TitleWrapper ref={titleRef}>
                <Title aria-label={data?.title}>
                  <CustomSplitText>{data?.title}</CustomSplitText>
                </Title>
              </TitleWrapper>
            )}
            {data?.excerpt && (
              <Excerpt ref={subtitleRef} className="excerpt" animated>
                {data?.excerpt}
              </Excerpt>
            )}
            {data?.resourceBlocks && (
              <>
                <BlocksContainer
                  ref={
                    data?.parentResource?.relatedResources?.length > 0
                      ? null
                      : resourceBlockContainerRef
                  }
                  className="white"
                  animated
                >
                  {data?.resourceBlocks?.map((block) => {
                    return (
                      <ResourceBlockContainer key={block.id}>
                        <ResourceBlock
                          title={block.title}
                          body={block.body}
                          ctas={block.ctAs}
                          tags={block.tags}
                        />
                      </ResourceBlockContainer>
                    );
                  })}
                  <BreadcrumbsContainer>
                    <Breadcrumbs
                      breadcrumbsText="Retour"
                      theme="secondary"
                      props={props}
                      exitDelay={2}
                      onClick={exitPage}
                    />
                  </BreadcrumbsContainer>
                </BlocksContainer>
              </>
            )}
          </StandartResourceContent>
        )}
      </Container>
    </ResourceWrapper>
  );
};

export default Resource;
